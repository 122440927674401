<template>
  <validation-observer tag="div" ref="observer" class="container" v-slot="{invalid}">
    <div class="hero-head">
      <div class="navbar">
        <div class="container">
          <div class="title">
            <h1>Cambiar contraseña de usuario {{ user.username }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <div class="container has-text-left">
        <div class="box">
          <div class="block">
            <validation-provider vid="newpassword" rules="required|min:6" name="newPassword">
              <b-field label="Nueva Contraseña"  slot-scope="{ errors, valid }"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors" >
                <b-input value="Password"
                         type="password"
                         v-model="changePasswordForm.password"
                         icon="key"
                         password-reveal
                         placeholder="Nueva Contraseña"
                >
                </b-input>
              </b-field>
            </validation-provider>
            <validation-provider rules="required|confirmed:newpassword" name="confirmPassword">
              <b-field label="Confirmar Contraseña" slot-scope="{ errors, valid }"
                       :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input value="Confirm Password"
                         type="password"
                         v-model="changePasswordForm.confirmPassword"
                         icon="key"
                         password-reveal
                         placeholder="Confirmar Contraseña"
                >
                </b-input>
              </b-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot">
      <div class="buttons is-right p-6">
        <b-button type="is-dark" @click="emitChangePasswordEvent">Volver</b-button>
        <b-button type="is-primary" :disabled="invalid" @click="changeUserPassword">Actualizar Contraseña
        </b-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
export default {
  name: 'ChangeUserPassword',
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      changePasswordForm: {
        confirmPassword: null,
        password: null
      },
      changingPassword: false
    }
  },
  methods: {
    changeUserPassword: function () {
      this.changingPassword = true
      this.axios.put(process.env.VUE_APP_API_ADMIN_RESET_USER_PASSWORD, {
        username: this.user.username,
        password: this.changePasswordForm.password
      }).then(() => {
        this.emitChangePasswordEvent()
        this.$notify.success({
          title: 'Contraseña actualizada',
          message: 'Se ha actualizado la contraseña del usuario correctamente'
        })
      }).catch(err => {
        console.error('No se puede cambiar la contraseña del usuario', err)
      }).finally(() => {
        this.changingPassword = false
      })
    },
    emitChangePasswordEvent (updated) {
      this.$emit('changePassword', updated)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
