var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [!_vm.addUser && !_vm.showChangeUserPassword ? _c('div', {
    attrs: {
      "id": "userTable"
    }
  }, [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  }, [_c('b-button', {
    staticClass: "button is-info is-primary",
    on: {
      "click": function click($event) {
        _vm.addUser = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "plus"
    }
  }), _c('span', [_vm._v("Añadir usuario")])], 1)], 1)])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('section', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block is-full"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": "",
      "position": "is-right"
    }
  }, [_c('b-field', [_c('b-input', {
    attrs: {
      "placeholder": "Buscar usuario...",
      "type": "search",
      "icon": "search"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.fetchUsers.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filters.userSearch,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "userSearch", $$v);
      },
      expression: "filters.userSearch"
    }
  })], 1), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-link",
    on: {
      "click": _vm.fetchUsers
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "filter"
    }
  }), _vm._v(" Filtrar ")], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "block m-4"
  }, [_c('b-table', {
    attrs: {
      "backend-pagination": "",
      "backend-sorting": "",
      "data": _vm.data,
      "draggable": "",
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingUsers,
      "current-page": _vm.currentPage
    },
    on: {
      "sort": _vm.handleTableSort,
      "dragstart": _vm.dragstart,
      "drop": _vm.drop,
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    staticClass: "is-info",
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": "",
      "width": "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "name",
      "label": "Nombre",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }], null, false, 2982927002)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "username",
      "label": "Usuario",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.username) + " ")];
      }
    }], null, false, 3112012715)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "level",
      "label": "Rol"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.role.name) + " ")];
      }
    }], null, false, 161064736)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "company.name",
      "label": "Empresa",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.company.name) + " ")];
      }
    }], null, false, 141863731)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.role.id > _vm.user.role.id ? _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Cambiar contraseña",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeUserPassword(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "key"
          }
        })], 1)], 1) : _vm._e(), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Editar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateUser(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Eliminar",
            "placement": "top"
          }
        }, [props.row.role.id > _vm.user.role.id ? _c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteUser(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, false, 4111867104)
  })], 1)], 1), _c('div', {
    staticClass: "block"
  }, [_c('el-pagination', {
    staticClass: "mt-5",
    attrs: {
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-sizes": [20, 50, 100, 200],
      "page-size": _vm.pageSize,
      "layout": "total, sizes, prev, pager, next",
      "total": _vm.totalElements
    },
    on: {
      "size-change": _vm.handlePageSizeChange,
      "current-change": _vm.handlePageChange,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)])])])])]) : _vm._e(), _vm.addUser ? _c('div', {
    attrs: {
      "id": ""
    }
  }, [_c('add-user', {
    attrs: {
      "is-editing": _vm.isEditing,
      "user": _vm.selectedUser
    },
    on: {
      "finishedAddingUser": _vm.handleUserAdded
    }
  })], 1) : _vm._e(), _vm.showChangeUserPassword ? _c('div', [_c('change-user-password', {
    attrs: {
      "user": _vm.selectedUser
    },
    on: {
      "changePassword": _vm.resetStatus
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Usuarios")])]);
}]

export { render, staticRenderFns }