var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    staticClass: "container",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Cambiar contraseña de usuario " + _vm._s(_vm.user.username))])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('div', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "vid": "newpassword",
            "rules": "required|min:6",
            "name": "newPassword"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return _c('b-field', {
                attrs: {
                  "label": "Nueva Contraseña",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Password",
                  "type": "password",
                  "icon": "key",
                  "password-reveal": "",
                  "placeholder": "Nueva Contraseña"
                },
                model: {
                  value: _vm.changePasswordForm.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.changePasswordForm, "password", $$v);
                  },
                  expression: "changePasswordForm.password"
                }
              })], 1);
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required|confirmed:newpassword",
            "name": "confirmPassword"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return _c('b-field', {
                attrs: {
                  "label": "Confirmar Contraseña",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Confirm Password",
                  "type": "password",
                  "icon": "key",
                  "password-reveal": "",
                  "placeholder": "Confirmar Contraseña"
                },
                model: {
                  value: _vm.changePasswordForm.confirmPassword,
                  callback: function callback($$v) {
                    _vm.$set(_vm.changePasswordForm, "confirmPassword", $$v);
                  },
                  expression: "changePasswordForm.confirmPassword"
                }
              })], 1);
            }
          }], null, true)
        })], 1)])])]), _c('div', {
          staticClass: "hero-foot"
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": _vm.emitChangePasswordEvent
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-primary",
            "disabled": invalid
          },
          on: {
            "click": _vm.changeUserPassword
          }
        }, [_vm._v("Actualizar Contraseña ")])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }