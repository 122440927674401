<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">

    <div id="userTable" v-if="!addUser && !showChangeUserPassword">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>Usuarios</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                  <b-button class="button is-info is-primary" @click="addUser=true">
                    <v-icon icon="plus" class="g-icon"></v-icon>
                    <span>Añadir usuario</span>
                  </b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="block">
            <section class="box">
              <div class="block is-full">
                <b-field grouped group-multiline position="is-right">
                  <b-field>
                    <b-input placeholder="Buscar usuario..." type="search" icon="search"
                             v-model="filters.userSearch" @keyup.native.enter="fetchUsers"></b-input>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button class="button is-link" @click="fetchUsers">
                        <v-icon icon="filter" class="g-icon"></v-icon>
                        Filtrar
                      </b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="block m-4">
                <b-table
                  backend-pagination
                  backend-sorting
                  :data="data"
                  @sort="handleTableSort"
                  draggable
                  @dragstart="dragstart"
                  @drop="drop"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  :hoverable="isHoverable"
                  :loading="loadingUsers"
                  :current-page.sync="currentPage">
                  <b-table-column centered field="id" label="REF" sortable v-slot="props" width="40" class="is-info">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column centered field="name" label="Nombre" sortable v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column centered field="username" label="Usuario" sortable v-slot="props">
                    {{ props.row.username }}
                  </b-table-column>
                  <b-table-column centered field="level" label="Rol" v-slot="props">
                    {{ props.row.role.name }}
                  </b-table-column>
                  <b-table-column centered field="company.name" label="Empresa" sortable v-slot="props">
                    {{ props.row.company.name }}
                  </b-table-column>
                  <b-table-column centered label="Acciones" v-slot="props" width="150">
                    <el-tooltip v-if="props.row.role.id > user.role.id" class="item" effect="dark"
                                content="Cambiar contraseña" placement="top">
                      <el-button size="mini" type="primary" circle @click="handleChangeUserPassword(props.row)">
                        <v-icon icon="key"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                      <el-button size="mini" type="warning" circle @click="handleUpdateUser(props.row)">
                        <v-icon icon="pen"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                      <el-button v-if="props.row.role.id > user.role.id" size="mini" type="danger" circle
                                 @click="handleDeleteUser(props.row)">
                        <v-icon icon="trash"></v-icon>
                      </el-button>
                    </el-tooltip>
                  </b-table-column>
                </b-table>
              </div>
              <div class="block">
                <el-pagination class="mt-5"
                               :hide-on-single-page="true"
                               @size-change="handlePageSizeChange"
                               @current-change="handlePageChange"
                               :current-page.sync="currentPage"
                               :page-sizes="[20, 50, 100, 200]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next"
                               :total="totalElements">
                </el-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div id="" v-if="addUser">
      <add-user @finishedAddingUser="handleUserAdded" :is-editing="isEditing" :user="selectedUser"></add-user>
    </div>
    <div v-if="showChangeUserPassword">
      <change-user-password :user="selectedUser" @changePassword="resetStatus"></change-user-password>
    </div>
  </section>
</template>

<script>
import AddUser from '../components/user/AddUser'
import * as types from '@/store/types'
import { mapGetters } from 'vuex'
import ChangeUserPassword from '@/components/user/ChangeUserPassword'

export default {
  name: 'User',
  components: { AddUser, ChangeUserPassword },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  },
  data () {
    return {
      addUser: false,
      currentPage: 1,
      userList: [],
      data: [],
      draggingRow: null,
      draggingRowIndex: null,
      filters: {
        userSearch: ''
      },
      isEditing: false,
      isHoverable: true,
      loadingUsers: false,
      pageSize: 20,
      rangeBefore: 3,
      rangeAfter: 1,
      selectedUser: null,
      showChangeUserPassword: false,
      sortOption: 'name,asc',
      totalElements: 200
    }
  },
  created () {
    this.fetchUsers()
  },
  methods: {
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      this.$buefy.toast.open(`Moved ${this.draggingRow.first_name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    },
    fetchUsers () {
      this.loadingUsers = true
      this.axios.get(process.env.VUE_APP_API_GET_USER, {
        params: {
          page: this.currentPage - 1,
          size: this.pageSize,
          sort: this.sortOption,
          searchQuery: this.getSearchQuery()
        }
      }).then(response => {
        const users = response.data.content
        this.totalElements = response.data.totalElements
        this.data = users.map(user => {
          return {
            id: user.id,
            name: user.name,
            username: user.username,
            role: user.role,
            level: user.level,
            company: user.company,
            active: user.active
          }
        })
      }).catch(err => {
        console.error('Error al obtener los usuarios', err)
      }).finally(() => {
        this.loadingUsers = false
      })
    },
    getSearchQuery () {
      let searchQuery = ''
      if (this.filters.userSearch) {
        searchQuery = 'name$' + this.filters.userSearch + '|username$' + this.filters.userSearch
      }
      return searchQuery
    },
    handleChangeUserPassword (user) {
      this.selectedUser = user
      this.showChangeUserPassword = true
    },
    handleDeleteUser (user) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Usuario',
        message: '¿Está seguro de que quiere <b>eliminar</b> al usuario? Esto eliminará los datos de usuario y sus respuestas al cuestionario',
        confirmText: 'Eliminar usuario',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_USER, {
            params: {
              userId: user.id,
              username: user.username
            }
          }).then(() => {
            this.$notify.success({
              title: 'Usuario eliminado',
              message: 'Usuario eliminado correctamente'
            })
            this.fetchUsers()
          }).catch(err => {
            console.log('No se puede eliminar al usuario', err)
          })
        }
      })
    },
    handlePageChange (page) {
      this.currentPage = page
      this.fetchUsers()
    },
    handlePageSizeChange (pageSize) {
      this.currentPage = 1
      this.pageSize = pageSize
      this.fetchUsers()
    },
    handleSelectUser (value) {
      this.filters.selectedUser = value
    },
    handleTableSort (field, order) {
      this.sortOption = field + ',' + order
      this.fetchUsers()
    },
    handleUpdateUser (user) {
      this.selectedUser = user
      this.isEditing = true
      this.addUser = true
    },
    handleUserAdded (isCreated) {
      if (isCreated) {
        this.fetchUsers()
      }
      this.resetStatus()
    },
    resetStatus () {
      this.addUser = false
      this.selectedUser = null
      this.isEditing = false
      this.sortOption = 'name,asc'
      this.filters.userSearch = ''
      this.showChangeUserPassword = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
