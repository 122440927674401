var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Nuevo usuario")])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('section', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Datos del usuario")])]), _c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return [_c('b-field', {
                attrs: {
                  "label": "Nombre*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "placeholder": "Nombre del puesto / trabajador"
                },
                model: {
                  value: _vm.userForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.userForm, "name", $$v);
                  },
                  expression: "userForm.name"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "user"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return [_c('b-field', {
                attrs: {
                  "label": "Usuario",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "placeholder": "Nombre de usuario"
                },
                model: {
                  value: _vm.userForm.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.userForm, "username", $$v);
                  },
                  expression: "userForm.username"
                }
              })], 1)];
            }
          }], null, true)
        }), !_vm.isEditing ? _c('validation-provider', {
          attrs: {
            "rules": "required|min:6",
            "name": "password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors,
                  valid = _ref4.valid;
              return [_c('b-field', {
                attrs: {
                  "label": "Contraseña",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "type": "password",
                  "placeholder": "Contraseña",
                  "password-reveal": ""
                },
                model: {
                  value: _vm.userForm.temporaryPassword,
                  callback: function callback($$v) {
                    _vm.$set(_vm.userForm, "temporaryPassword", $$v);
                  },
                  expression: "userForm.temporaryPassword"
                }
              })], 1)];
            }
          }], null, true)
        }) : _vm._e(), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "role"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors,
                  valid = _ref5.valid;
              return [_c('b-field', {
                attrs: {
                  "label": "Rol",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-select', {
                attrs: {
                  "expanded": "",
                  "loading": _vm.loadingRoles,
                  "placeholder": "Rol"
                },
                model: {
                  value: _vm.userForm.role.id,
                  callback: function callback($$v) {
                    _vm.$set(_vm.userForm.role, "id", $$v);
                  },
                  expression: "userForm.role.id"
                }
              }, _vm._l(_vm.roleList, function (option, i) {
                return _c('option', {
                  key: i,
                  domProps: {
                    "value": option.id
                  }
                }, [_vm._v(_vm._s(option.name))]);
              }), 0)], 1)];
            }
          }], null, true)
        }), _c('validation-provider', {
          ref: "companyProvider",
          attrs: {
            "rules": "required",
            "name": "company"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var valid = _ref6.valid,
                  errors = _ref6.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Seleccionar empresa*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-autocomplete', {
                attrs: {
                  "data": _vm.companyList,
                  "open-on-focus": true,
                  "field": "name",
                  "placeholder": "Buscar Empresa...",
                  "loading": _vm.loadingCompanies,
                  "icon": "search",
                  "clearable": ""
                },
                on: {
                  "blur": _vm.checkCompanySelected,
                  "focus": function focus($event) {
                    return _vm.fetchCompanies();
                  },
                  "typing": _vm.fetchCompanies,
                  "input": _vm.validateCompany,
                  "select": _vm.handleSelectCompany
                },
                scopedSlots: _vm._u([{
                  key: "empty",
                  fn: function fn() {
                    return [_vm._v("Sin resultados")];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(props) {
                    return [_c('div', {
                      staticClass: "media"
                    }, [_c('div', {
                      staticClass: "media-content"
                    }, [_vm._v(_vm._s(props.option.name))])])];
                  }
                }], null, true),
                model: {
                  value: _vm.selectedCompany,
                  callback: function callback($$v) {
                    _vm.selectedCompany = $$v;
                  },
                  expression: "selectedCompany"
                }
              })], 1)];
            }
          }], null, true)
        })], 1)])])]), _c('div', {
          staticClass: "hero-foot "
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": _vm.finishAddingUser
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-success",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveOrUpdateUser
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar' : 'Guardar'))])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }