<template>
  <validation-observer ref="observer" tag="div" v-slot="{invalid}">
    <div class="hero-head">
      <div class="navbar">
        <div class="container">
          <div class="title">
            <h1>Nuevo usuario</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <div class="container has-text-left">
        <section class="box">
          <div class="title">
            <h1>Datos del usuario</h1>
          </div>
          <div class="block">
            <validation-provider rules="required" name="name" v-slot="{errors, valid}">
              <b-field label="Nombre*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input placeholder="Nombre del puesto / trabajador" v-model="userForm.name"></b-input>
              </b-field>
            </validation-provider>
            <validation-provider rules="required" name="user" v-slot="{errors, valid}">
              <b-field label="Usuario" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
                <b-input placeholder="Nombre de usuario" v-model="userForm.username"></b-input>
              </b-field>
            </validation-provider>
            <validation-provider v-if="!isEditing" rules="required|min:6" name="password" v-slot="{errors, valid}">
              <b-field label="Contraseña" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input type="password" placeholder="Contraseña" v-model="userForm.temporaryPassword"
                         password-reveal></b-input>
              </b-field>
            </validation-provider>
            <validation-provider rules="required" name="role" v-slot="{errors, valid}">
              <b-field  label="Rol" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-select expanded :loading="loadingRoles" placeholder="Rol" v-model="userForm.role.id">
                  <option v-for="(option, i) in roleList" :value="option.id" :key="i">{{ option.name }}</option>
                </b-select>
              </b-field>
            </validation-provider>
            <validation-provider ref="companyProvider" rules="required" name="company" v-slot="{valid,
            errors}">
              <b-field label="Seleccionar empresa*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-autocomplete
                  :data="companyList"
                  @blur="checkCompanySelected"
                  :open-on-focus="true"
                  @focus="fetchCompanies()"
                  field="name"
                  placeholder="Buscar Empresa..."
                  :loading="loadingCompanies"
                  icon="search"
                  @typing="fetchCompanies"
                  @input="validateCompany"
                  clearable
                  @select="handleSelectCompany"
                  v-model="selectedCompany">
                  <template #empty>Sin resultados</template>
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content">{{ props.option.name }}</div>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </validation-provider>
          </div>
        </section>
      </div>
    </div>
    <div class="hero-foot ">
      <div class="buttons is-right p-6">
        <b-button type="is-dark" @click="finishAddingUser">Volver</b-button>
        <b-button type="is-success" :disabled="invalid" @click="saveOrUpdateUser">{{ isEditing ? 'Actualizar' :
          'Guardar'
          }}</b-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'AddUser',
  props: {
    isEditing: {
      required: true
    },
    user: {
      required: true,
      type: Object
    }
  },
  async created () {
    console.log('Fetching')
    await this.fetchRoles()
    if (this.isEditing) {
      console.log('Editing')
      this.userForm.id = this.user.id
      this.userForm.name = this.user.name
      this.userForm.username = this.user.username
      this.userForm.role = this.user.role
      this.userForm.company = this.user.company
      this.selectedCompany = this.user.company.name
      this.userForm.active = this.user.active
    }
  },
  data () {
    return {
      companyList: [],
      loadingCompanies: false,
      loadingRoles: false,
      selectedCompany: null,
      roleList: [],
      userForm: {
        id: null,
        name: null,
        username: null,
        temporaryPassword: null,
        role: {
          id: null
        },
        company: {
          id: null
        },
        active: false
      }
    }
  },
  methods: {
    checkCompanySelected () {
      if (!this.selectedCompany || !this.roleList.includes(this.selectedCompany)) {
        this.userForm.company.id = null
        this.selectedCompany = null
      }
    },
    fetchCompanies: debounce(function (companyName) {
      this.loadingCompanies = true
      this.axios.get(process.env.VUE_APP_API_GET_COMPANY, {
        params: {
          searchQuery: companyName && companyName !== '' ? 'name$' + companyName : null
        }
      }).then(response => {
        this.companyList = response.data.content
      }).catch(err => {
        console.error('Error al obtener las empresas', err)
      }).finally(() => {
        this.loadingCompanies = false
      })
    }),
    async fetchRoles () {
      this.loadingRoles = true
      console.log(this.userRole)
      await this.axios.get(process.env.VUE_APP_API_GET_ROLE, {
        params: {
          searchQuery: 'id>' + this.userRole.id,
          page: 0,
          size: 2000,
          order: 'id,desc'
        }
      }).then(response => {
        console.log('Roles fetched')
        this.roleList = response.data.content
      }).catch(err => {
        console.error('No se pueden cargar los roles', err)
        this.$notify.error({
          title: 'Error',
          message: 'No se pueden cargar los roles'
        })
      }).finally(() => {
        this.loadingRoles = false
      })
    },
    finishAddingUser () {
      this.$emit('finishedAddingUser')
    },
    handleSelectCompany (option) {
      this.selectedCompany = option.name
      this.userForm.company.id = option.id
    },
    saveUser () {
      this.axios.post(process.env.VUE_APP_API_CREATE_USER, this.userForm).then(() => {
        this.$notify.success({
          title: 'Usuario creado',
          message: 'El usuario se ha creado correctamente'
        })
        this.$emit('finishedAddingUser', true)
      }).catch(err => {
        console.error('No se ha podido crear el usuario', err)
      })
    },
    saveOrUpdateUser () {
      if (this.isEditing) {
        this.updateUser()
      } else {
        this.saveUser()
      }
    },
    updateUser () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_USER, this.userForm).then(() => {
        this.$notify.success({
          title: 'Usuario actualizado',
          message: 'El usuario se ha actualizado correctamente'
        })
        this.$emit('finishedAddingUser', true)
      })
    },
    validateCompany (e) {
      this.$refs.companyProvider.validate(e)
    }
  },
  computed: {
    ...mapGetters({
      userRole: types.GET_ROLE
    })
  }
}
</script>

<style lang="scss">

</style>
